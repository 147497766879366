@import './GlobalStyles/colors';

$padding-button: 0.1rem 0.9em 0.1em 0.5em;
$font-size-button: 1.2em;
$font-familly-bt-title: 'League Spartan', sans-serif;

.row-spacing {
  margin: 15px 15px 15px 15px;
  text-align: center;

  .video-description-colored-text {
    color: $base-color-green;
  }

  .lot-jeu {
    padding: 10px;
    background-image: url("./Images/WantedFrond.jpg");
    border-left: 2px solid $base-color-dark-blue;
    border-right: 2px solid $base-color-dark-blue;
    border-radius: 10px;
  
    img {
      margin: .5em 0; 
    }
  }
}

.row-functionality {
  margin-bottom: 25px;
}

.title-centered {
  border-bottom: 1px solid #FCCC1C;
  font-size: 20px;
  font-family: $font-familly-bt-title;
}

.row-spacing-title-left {
  margin: 15px 0px 15px 0px;

  .container-title-left {
    text-align: right;
    border-bottom: 1px solid #FCCC1C;

    .title-left-side {
      font-size: 20px;
      font-family: $font-familly-bt-title;
    }
  }
}

.home-image {
  border-radius: 10px;
}

.button-row-spacing {
  text-align: end;
  margin: 15px 30px 20px 0px;
}

.last-button-row-spacing {
  text-align: end;
  margin: 25px 30px 20px 0px;
}

.hat-one-peace {
  width: 100px;
  align-self: flex-end;
  margin-top: -20px;
  top: 20px;
  z-index: 1;
}

.equipe-keepcod {
  flex-flow: column;
}

.primary-button {
  padding: $padding-button;
  font-size: $font-size-button;
  font-size: 20px;
  font-family: $font-familly-bt-title;
}

.text-antd {
  margin-bottom: 3em;
}
