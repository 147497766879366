@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@700&family=Manrope:wght@700&display=swap');
@import "./colors";

body {
  margin: 0px;
  background-color: $base-color-blue;
  color: $base-color-white;
  font-family: 'Manrope', sans-serif;
}

.ant-typography {
  color: $base-color-white;
}