@import '../../GlobalStyles/colors';

.container-custom-card {

  .container-text-card {
    padding: 10px;
    text-align: center;
    background-image: linear-gradient(to bottom,
        $base-color-light-blue-background,
        $base-color-light-blue-background), url("../../Images/WantedFrond.jpg");
    border-radius: 10px;
  }
}