@import '../../GlobalStyles/colors';


.container-footer {
    background-image: url("../../Images/WantedFrond.jpg");

    .row-footer {
        align-items: center;
        justify-content: center;

        .button-mentions-legal {
            color: $base-color-white;

        }

        .button-mentions-legal:hover {
            color: $base-color-white;
        }
    }

    .social-network {
        .social-logo {
            font-size: 39px;

            svg {
                margin: 4px;
            }
        }

        .we-love-dev {
            font-size: 111px;
            svg {
                height: 70px;
            }
        }
    }

    .mention-legal {
        margin: -16px 0 10px 0;
    }

}






